import { hover } from "@testing-library/user-event/dist/hover";

const imageUrl = process.env.PUBLIC_URL + '/images/banner_camp.jpg';
const backgroundEventsImage = process.env.PUBLIC_URL + '/images/subpage-header-block-bg.jpg';
const backgroundPricesImage = process.env.PUBLIC_URL + '/images/mega-cavern-bike-park.jpg';
const separatorImage = process.env.PUBLIC_URL + '/images/separator.png';

// HeroStyles.ts
const styles  = {
 anchorColor:{
  color:"#efc920"
 },
 zindexMax:{
  zIndex:10
 },
 mainBackgroundColor:{
  position:"relative",
  zIndex:3,
  backgroundColor: "#231f20", // Change this to your desired color
 },
 dropdownStyles : {
    backgroundColor: "#231f20", // Change this to your desired color
    color: "#fff",
    marginTop: "-10px",
    marginBottom: "-10px",
    // Add other styles you want to apply
  },
  margin0:{
      margin:"0"
  },
 width100:{
    width:"100%"
 },
 servicesImage:{
  width:"150px",
  marginTop:"16px"
},
 height15:{
  height:"15px"
}, 
marginTop15:{
  marginTop:"15px"
},
marginTop50:{
  marginTop:"50px"
},
eventsContainer:{
  zIndex: 3,
  position: "relative",
  color:"#ffffff"
  },

marginSeparator:{
  position:"relative",
  zIndex:3,
  height:"15px",
  backgroundImage: `url(${separatorImage})`,

},
actionCampImage:{
  width: "42%",
  left: "29%",
  position: "relative"
},
texAlignCenter:{
  textAlign:"center"
},
displayInline:{
  display:"inline"
},
about:{
    backgroundColor: "#231f20",
    padding: "25px",
    color:"#ffffff",  
    zIndex:2,
    position:"relative"
},

personal:{
  textAlign:"center",
  padding:"20px",
  height:"400px",
  backgroundImage: `url(${backgroundEventsImage})`,
  backgroundSize: 'cover',
  zIndex:2,
  position:"relative"

},
headerDropdown:{
 backgroundColor:"#231f20",
 color:"#fffff"
},

personalOverlay: {
  position: "absolute",
  top: "30",
  left: "0",
  width: "100%",
  zIndex: 2,
  height: "400px",
  backgroundColor: "rgba(0, 0, 0, 0.5)" /* Adjust the alpha value for transparency */
},

icons:{
padding:"15px"
},

serviceContainer:{
  backgroundColor: "#231f20",
  color:"#ffff",
  textAlign:"center",
  padding:"25px",
  zIndex:2,
  position:"relative"
},
  aboutTitle:{
    textAlign:"center",
    paddingBottom:"10px"
  },
  header: {
    zIndex:10000,
    backgroundColor: "#231f20",
  },
  headerPosition:{
    display: "flex",
    marginRight:"auto",
    marginLeft:"auto",
  },
  headerPositionMobile:{
    display: "block",
    marginLeft: "10%"
  },
  overlay: {
    position: "absolute",
    top: "30",
    left: "0",
    width: "100%",
    height: "600px",
  },

  actionCampSvg: {
    height:"10px",
    marginBottom: "-1000px",
    zIndex:"1",
    position: "fixed",
    marginLeft: "55px",
  },
  actionCampSvgMobile: {
    height:"10px",
    marginBottom: "-1000px",
    zIndex:"1",
    position: "fixed",
    marginLeft: "-150px",
  },
  banner: {
    width: '100%',
    height: '600px',
    backgroundSize: 'cover',
    position: 'relative',
  },

  cardStyle:{
    width:"500px",
    backgroundColor:"#efc920"
  },
  prices: {
    padding:"25px",
    textAlign:"center",
    color:"#ffffff",
    width: '100%',
    backgroundImage: `url(${backgroundPricesImage})`,
    backgroundSize: 'cover',
    zIndex:2,
    position:"relative"
    },
  bannerText:{
    margin:"20px"
  },
  container: {
    position: 'relative',
  },
  bannerButton: {
    width:"415px",
    height:"100px",
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '56px',
    backgroundColor: "#efc920",
  },

  bannerButtonMobile: {
    width:"200px",
    height:"50px",
    fontSize: '24px',
    fontWeight: 700,   
    lineHeight: '26px',
    backgroundColor:"#efc920",
    "&:hover": {
      backgroundColor: "#efefef"
    },
  },
  title: {
    position:"relative",
    top:"300px",
    marginLeft: '100px',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '56px',
    color: '#FFFFFF',
  },
  titleSpan: {
    color: '#e1b822',
  },
  titleMobile: {
    position:"relative",
    top:"300px",
    marginLeft: '20px',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '56px',
    color: '#FFFFFF',
  },
  subtitle: {
    position:"relative",
    top:"300px",
    marginLeft:"100px",
    color: '#FFFFFF',
    fontSize: '24px',
    fontWeight: 400,
  },
  subtitleMobile: {
    position:"relative",
    top:"300px",
    marginLeft:"20px",
    color: '#FFFFFF',
    fontSize: '24px',
    fontWeight: 400,
  },
  noBullets:{
    listStyleType: "none"
  },

  '@media (min-width: 1024px)': {

  },
  '@media (max-width: 768px)': {
    title: {
      fontSize: '28px',
      lineHeight: '36px',
    },
    subtitle: {
      fontSize: '18px',
      lineHeight: '24px',
      marginBottom: '30px',
    }
  },
  '@media (max-height: 500px)': {
    banner: {
      height: '120vh',
    },
  },

  
} as const;

export default styles;
