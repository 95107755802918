import { useEffect, useRef, useState } from "react";
import styles from "../styles/styles";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CampIcon } from "../iconComponents/actionCamp.svg"; // Import the SVG file
import { Button, Col, Container, Row } from "react-bootstrap";

function Banner() {
  const videoRef = useRef<HTMLVideoElement>(null);

  const restartVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reset video to the beginning
      videoRef.current.play();
    }
  };

  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 992 ? false : true
  );
  const [isMobileVideo, setIsMobileVideo] = useState(
    window.innerWidth > 500 ? false : true
  );
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    if (window.innerWidth < 500) {
      setIsMobileVideo(true);
    } else {
      setIsMobileVideo(false);
    }
  };
  const navigateToCamp = () => {
    // 👇️ navigate to /contacts
    navigate("/actionCamp");
  };
  const handleClick = () => {
    // You might want to use smooth scrolling to the new component
    const element = document.getElementById("actionCamp");
    if (element) {
      const offsetTop = element.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };
  const buttonStyle = {
    ...(isMobile ? styles.bannerButtonMobile : styles.bannerButton),
    backgroundColor: isHovered
      ? "#231f20"
      : isMobile
      ? styles.bannerButtonMobile.backgroundColor
      : styles.bannerButton.backgroundColor,
    color: isHovered ? "#ffff" : "#231f20",
    border: "none",
  };
  return (
    <>
      <div style={isMobile ? styles.actionCampSvgMobile : styles.actionCampSvg}>
        <CampIcon
          height={
            window.innerWidth < 600
              ? 200
              : window.innerWidth > 400 && window.innerWidth < 992
              ? 350
              : 1000
          }
        ></CampIcon>
      </div>
      {isMobileVideo === false ? (
        <video
          width="100%"
          height="auto"
          autoPlay
          muted
          ref={videoRef}
          onEnded={restartVideo}
        >
          <source
            src={process.env.PUBLIC_URL + "/video/main_video.mp4"}
            type="video/mp4"
          />
        </video>
      ) : (
        <img
          width={"100%"}
          alt="banner_photo"
          src={process.env.PUBLIC_URL + "/images/ac_banner.png"}
        />
      )}
      <Container
        style={
          isMobile === false
            ? { marginTop: "-120px" }
            : { marginTop: "-60px", width: "100%" }
        }
      >
        <Row style={{ position: "relative", top: "-30px" }}>
          <Col xl={4}></Col>
          <Col xl={4}></Col>
          <Col style={styles.zindexMax} onClick={handleClick} xl={4}>
            <Button
              onMouseEnter={() => setIsHovered(true)} // Set hover state to true on mouse enter
              onMouseLeave={() => setIsHovered(false)}
              style={buttonStyle}
              variant="warning"
            >
              Sign up now
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Banner;
