import { useEffect, useState } from "react";
import styles from "../styles/styles";

function ActionCamp() {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 600 ? false : true
  );
  const handleResize = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  return (
    <>
      <div id="actionCamp" style={styles.mainBackgroundColor}>
        {/* <div>
          <img
            style={styles.actionCampImage}
            src={process.env.PUBLIC_URL + "/images/action_camp_form.png"}
          />
        </div> */}
        <div>
          <iframe
            width={"100%"}
            height={isMobile ? "2350px" : "1800px"}
            src="https://docs.google.com/forms/d/e/1FAIpQLScObJNONNIC0s2RbTu6zK1Zz_L7Oa-rbICFQVmGAp-yPHxQ1g/viewform?embedded=true"
            scrolling="no"
          ></iframe>
        </div>
      </div>
      <div style={styles.marginSeparator}></div>
    </>
  );
}
export default ActionCamp;
