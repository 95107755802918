import Carousel from "react-bootstrap/Carousel";
import styles from "../styles/styles";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as Rockshox } from "../iconComponents/rockshox.svg"; // Import the SVG file

function Footer() {
  return (
    <>
      <div style={styles.about}>
        <Container data-aos="fade-up">
          <Row>
            <Col
              lg={4}
              className="pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img src={process.env.PUBLIC_URL + "/images/AC-logo_v4.jpg"} />
              <div>
                <p>Alatura-te</p>

                <div>
                  <a
                    target="_blank"
                    style={styles.icons}
                    href="https://www.instagram.com/action.cloud/"
                    className="twitter"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/instagram-2-32.ico"
                      }
                    />
                  </a>
                  <a
                    target="_blank"
                    style={styles.icons}
                    href="https://www.facebook.com/profile.php?id=100067453983138&sk=about"
                    className="facebook"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/facebook-3-32.ico"
                      }
                    />
                  </a>
                  <a
                    target="_blank"
                    style={styles.icons}
                    href="https://www.youtube.com/@_actioncloud"
                    className="instagram"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/icons/youtube-32.ico"
                      }
                    />
                  </a>
                  <a
                    target="_blank"
                    style={styles.icons}
                    href="https://www.tiktok.com/@action.cloud"
                    className="google-plus"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/icons/tiktok-3-32.ico"
                      }
                    />
                  </a>
                </div>
              </div>
            </Col>
            <Col
              lg={4}
              className="pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div>
                <p>Unde ne gasesti</p>
                <div>
                  <p>Adresa: Str. Stadionului 15</p>
                  <p>Telefon: 0741780078</p>
                  <p>
                    Email:{" "}
                    <a
                      style={styles.anchorColor}
                      href="mailto:recipient@example.com"
                    >
                      actioncloud@gmail.com{" "}
                    </a>
                  </p>
                  <p>Program:</p>
                  <ul>
                    <li>Sambata-Duminica:11:00-20:00</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col
              lg={4}
              className="pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {/* <div>
                <div>
                  <ul style={styles.noBullets}>
                    <Row>
                      <Col>
                        <li>
                          <a href="#" className="google-plus">
                            <Rockshox></Rockshox>
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a href="#" className="google-plus">
                            <Rockshox></Rockshox>
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a href="#" className="google-plus">
                            <Rockshox></Rockshox>
                          </a>
                        </li>
                      </Col>
                      <Col>
                        <li>
                          <a href="#" className="google-plus">
                            <Rockshox></Rockshox>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="google-plus">
                            <Rockshox></Rockshox>
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a href="#" className="google-plus">
                            <Rockshox></Rockshox>
                          </a>
                        </li>
                      </Col>
                    </Row>
                  </ul>
                </div>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default Footer;
