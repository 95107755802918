import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import styles from "../styles/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth > 992 ? false : true
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  return (
    <>
      <Navbar
        sticky="top"
        className="navbar navbar-expand-lg"
        style={styles.header}
        variant="dark"
        expand="lg"
      >
        <div
          style={
            isMobile === false
              ? styles.headerPosition
              : styles.headerPositionMobile
          }
          className="justify-content-center"
        >
          <Navbar.Brand href="/">
            <img
              alt="banner_photo"
              src={process.env.PUBLIC_URL + "/images/AC-logo_v4.png"}
            />
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Collapse id="basic-navbar-nav">
            {/* <Nav className="justify-content-end">
              <Nav.Item>
                <Link to="/actionCamp" className="nav-link">
                  Camps
                </Link>
              </Nav.Item>
              <Nav.Link href="#DespreNoi">Kids</Nav.Link>
              <Nav.Link href="#Evenimente">Coach</Nav.Link>
              <Nav.Link href="#Sponsori">Info</Nav.Link>
              <NavDropdown title="Parinti" id="basic-nav-dropdown">
                <NavDropdown.Item style={styles.dropdownStyles} href="/">
                  Siguranta
                </NavDropdown.Item>
                <NavDropdown.Item style={styles.dropdownStyles} href="/">
                  Briefing
                </NavDropdown.Item>
                <NavDropdown.Item
                  style={styles.dropdownStyles}
                  href="#action/3.3"
                >
                  Declaratii
                </NavDropdown.Item>
                <NavDropdown.Item
                  style={styles.dropdownStyles}
                  href="#action/3.3"
                >
                  FAQ
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#Contact">Contact</Nav.Link>
            </Nav> */}
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}
export default Header;
