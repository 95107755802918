import { Card, Button } from "react-bootstrap";
import styles from "../styles/styles";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as Ramp } from "../iconComponents/ramp.svg"; // Import the SVG file
import { ReactComponent as Bike } from "../iconComponents/bike.svg"; // Import the SVG file
import { ReactComponent as Helmet } from "../iconComponents/helmet.svg"; // Import the SVG file

function Services() {
  return (
    <>
      <div style={styles.marginSeparator}></div>
      <div style={styles.serviceContainer}>
        <Container data-aos="fade-up">
          <div className="section-title">
            <h2>Ce va oferim</h2>
          </div>

          <Row>
            <Col
              lg={4}
              md={6}
              className="d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <Card style={styles.cardStyle}>
                <div>
                  {" "}
                  <Card.Img
                    style={styles.servicesImage}
                    sizes="small"
                    variant="top"
                    src={process.env.PUBLIC_URL + "/images/coach.png"}
                  />
                </div>
                <Card.Body>
                  <Card.Text>Hai la antrenament</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col
              lg={4}
              md={6}
              className="d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <Card style={styles.cardStyle}>
                <div>
                  {" "}
                  <Card.Img
                    style={styles.servicesImage}
                    sizes="small"
                    variant="top"
                    src={process.env.PUBLIC_URL + "/images/camp.png"}
                  />
                </div>
                <Card.Body>
                  <Card.Text>Hai la tabara</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col
              lg={4}
              md={6}
              className="d-flex align-items-stretch mt-4 mt-lg-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <Card style={styles.cardStyle}>
                <div>
                  {" "}
                  <Card.Img
                    style={styles.servicesImage}
                    sizes="small"
                    variant="top"
                    src={process.env.PUBLIC_URL + "/images/kids.png"}
                  />
                </div>
                <Card.Body>
                  <Card.Text>Hai la petrecere!</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={styles.marginSeparator}></div>
    </>
  );
}
export default Services;
