import Banner from "../sharedComponents/banner";
import Services from "../sharedComponents/services";
import Events from "../sharedComponents/Events";
import About from "../sharedComponents/about";
import Prices from "../sharedComponents/prices";
import Safety from "../sharedComponents/safety";
import FAQ from "../sharedComponents/FAQ";
import ActionCamp from "../sharedComponents/actionCamp";

function ParentPage() {
  return (
    <>
      <Banner />
      <Services />
      <ActionCamp />
      {/* <Safety />
      <Prices />
      <FAQ />
      <Events /> */}
    </>
  );
}
export default ParentPage;
