import Header from "./sharedComponents/header";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // Import the CSS file
import Footer from "./sharedComponents/footer";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import ActionCamp from "./sharedComponents/actionCamp";
import ParentPage from "./components/ParentPage";

function App() {
  return (
    <>
      <HashRouter>
        <Header></Header>

        <Routes>
          <Route path="/" element={<ParentPage />}></Route>
          <Route path="/actionCamp" element={<ActionCamp />}></Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer></Footer>
      </HashRouter>
    </>
  );
}

export default App;
